export default [
  {
    path: "/",
    component: (): Promise<typeof import("*.vue")> => import(
      /* webpackChunkName: "home-route" */
      /* webpackPrefetch: true */
      "@/routes/routeHome.vue"
    ),
    children: [
      {
        path: "",
        name: "home",
        components: {
          default: (): Promise<typeof import("*.vue")> => import(
            /* webpackChunkName: "home-view" */
            /* webpackPrefetch: true */
            /* webpackFetchPriority: "high" */
            "@/views/viewHome.vue"
          ),
        },
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
]
