import { GetterTree, ActionTree, MutationTree, Module } from "vuex"
import { RootState } from "@/plugins/store/interfaces/RootState"

const state = {
  "vitrine": {}
}

const getters: GetterTree<typeof state, RootState> = {
  currentVitrine: ({ vitrine }: typeof state) => () => {
    return vitrine
  },
}

const actions: ActionTree<typeof state, RootState> = {
  ActionSnapshotProducts ({ commit }, data: Record<string, string>) {
    commit("MutationSnapshotProducts", data)
  },
}

const mutations: MutationTree<typeof state> = {
  MutationSnapshotProducts (state, data: Record<string, string>) {
    state.vitrine = data
  },
}

const moduleStoreProducts: Module<typeof state, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default moduleStoreProducts
