import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#A69485",
        secondary: "#F2E0D0",
        accent: "#BFB4AA",
        background: "#F2F2F2",
        border: "#0D0D0D",
      },
    },
    icons: {
      iconfont: "md",
    },
  }
});
