import { GetterTree, ActionTree, MutationTree, Module } from "vuex"
import { RootState } from "@/plugins/store/interfaces/RootState"

const state = {
  "currentUser": {
    "emailVerified": false,
  }
}

const getters: GetterTree<typeof state, RootState> = {
  currentUserEmailVerified: ({ currentUser }: typeof state) => () => {
    return currentUser.emailVerified
  },
}

const actions: ActionTree<typeof state, RootState> = {
  ActionCurrentUserEmailVerified ({ commit }, data: boolean) {
    commit("MutationCurrentUserEmailVerified", data)
  },
}

const mutations: MutationTree<typeof state> = {
  MutationCurrentUserEmailVerified ({ currentUser }: typeof state, data: boolean) {
    currentUser.emailVerified = data
  },
}

const moduleStoreUser: Module<typeof state, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default moduleStoreUser
