import md5 from "md5"
import {
  FirebaseApp,
  FirebaseOptions,
  initializeApp,
} from "firebase/app";
import {
  getAnalytics,
  logEvent,
} from "firebase/analytics";
import {
  getAuth,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  User,
} from "firebase/auth";
import {
  doc,
  onSnapshot,
  getFirestore,
  collection,
  query,
  where,
  getDoc,
  DocumentData,
} from "firebase/firestore";
import {
  initializeAppCheck,
  ReCaptchaV3Provider,
} from "firebase/app-check";

import App from "@/main"

const FIREBASE_CONFIG: FirebaseOptions = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  projectId: process.env.VUE_APP_PROJECTID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPID,
  measurementId: process.env.VUE_APP_MEASUREMENTID,
}

const FIREBASE_APP = initializeApp(FIREBASE_CONFIG);
const FIREBASE_ANALYTICS = getAnalytics(FIREBASE_APP as FirebaseApp);
const FIREBASE_AUTH = getAuth(FIREBASE_APP as FirebaseApp);
const FIREBASE_STORE = getFirestore(FIREBASE_APP as FirebaseApp);

initializeAppCheck(FIREBASE_APP, {
  provider: new ReCaptchaV3Provider(process.env.VUE_APP_RECAPTCHA_V3),
  isTokenAutoRefreshEnabled: true,
});

export function event (event: string, params?: object): void {
  logEvent(FIREBASE_ANALYTICS, event, { params });
}

onAuthStateChanged(FIREBASE_AUTH, async (user) => {
  const { emailVerified } = user || { "emailVerified": false }
  await App.store.dispatch("moduleStoreUser/ActionCurrentUserEmailVerified", emailVerified);

  if (emailVerified) getProducts()
});

async function createAuthUser ():  Promise<void> {
  const GOOGLE_PROVIDER = new GoogleAuthProvider();

  // https://firebase.google.com/docs/auth/web/service-worker-sessions?hl=pt&authuser=1
  await signInWithPopup(FIREBASE_AUTH, GOOGLE_PROVIDER)
    .catch(console.error);
}

export async function currentUser (): Promise<User> {
  const CURRENT_USER = FIREBASE_AUTH.currentUser as User
  if (!CURRENT_USER) await createAuthUser();
  return FIREBASE_AUTH.currentUser as User
}

export function logOut () {
  signOut(FIREBASE_AUTH)
}

async function getProducts () {
  // https://firebase.google.com/docs/firestore/query-data/listen
  const QUERY = query(collection(FIREBASE_STORE, "produtos"), where("quantidade", ">=", 1))
  onSnapshot(QUERY, (produtos) => {
    const PRODUTO = new Set()
    produtos.forEach((produto) => {
      const {
        id = produto.id,
        pvp,
        quantidade,
      } = produto.data()

      PRODUTO.add({
        id,
        pvp,
        quantidade,
      })
    })
    App.store.dispatch("moduleStoreProducts/ActionSnapshotProducts", PRODUTO);
  });
}

export async function getFornecedor (searchUid?: string) {
  const { uid } = await currentUser()

  // https://firebase.google.com/docs/firestore/solutions/presence?hl=pt-br
  return new Promise((resolve) => {
    onSnapshot(doc(FIREBASE_STORE, "fornecedores", searchUid || uid), async (fornecedor) => {
      resolve(fornecedor.data())
    })
  })
}

export async function getItem (product: string): Promise<DocumentData | undefined> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const DOC = doc(FIREBASE_STORE, "items", md5(product))
    const DATA_ITEM = getDoc(DOC)
    resolve((await DATA_ITEM).data())
  })
}

export async function getItemLegend (): Promise<DocumentData | undefined> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const DOC = doc(FIREBASE_STORE, "items", "legenda")
    const DATA_ITEM = getDoc(DOC)
    resolve((await DATA_ITEM).data())
  })
}

event("app_start");
