import Vue from "vue";
import Vuex, { StoreOptions } from "vuex"
import moduleStoreUser from "@/plugins/store/modules/moduleStoreUser";
import moduleStoreProducts from "@/plugins/store/modules/moduleStoreProducts";
import { RootState } from "@/plugins/store/interfaces/RootState"

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  strict: false,
  modules: {
    moduleStoreUser,
    moduleStoreProducts,
  },
}

export default new Vuex.Store<RootState>(store)
